.error {
    width: 100%;
    text-align: 70vh;
    text-align: center;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    font-family: 'khula_regular';
    font-size: (10vw);
}