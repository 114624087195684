// Container & Breakpoints
@use 'sass:math';
$containerWidth: 1200;
$containerPadding: 40;
$containerSidePadding: math.div($containerPadding, 2);
$breakpoint1450: 1449.98+px;
$breakpoint992: 991.98+px;
$breakpoint768: 767.98+px;
$breakpoint480: 479.98+px;

$ser: #48556e;
$ui-color-gray-white: #f5f7fa;
$ui-color-gray-dark: #8d95a5;
// $blue: rgb(0 91 255 / 100%);
$base-layout-padding: 24px;
$base-layout-content-bg: $ui-color-gray-white;

$mainColor: #5201a9;


$ui-color-blue-bg: #e9f0fd;
$ui-color-blue-light: #92b4f6;
$ui-color-blue: #216bff;
$ui-color-blue-dark: #1849a9;
$ui-color-green-bg: #dffae8;
$ui-color-green-light: #93ddac;
$ui-color-green: #3ac267;
$ui-color-green-dark: #268045;
$ui-color-yellow-bg: #fff7d1;
$ui-color-yellow-light: #ffed8a;
$ui-color-yellow: #ffd900;
$ui-color-yellow-dark: #e59900;
$ui-color-red-bg: #fde5ec;
$ui-color-red-light: #f47b9f;
$ui-color-red: #df2059;
$ui-color-red-dark: #861313;
$ui-color-gray-white: #f5f7fa;
$ui-color-gray-light: #e6e9f0;
$ui-color-gray: #d4d9e2;
$ui-color-gray-dark: #8d95a5;
$ui-color-white: #fff;
$ui-color-black-light: #48556e;
$ui-color-black: #172133;
$ui-color-purple: #a57bf1;
$ui-color-azure: #44a9e3;
$ui-color-blues: #337cff;
$ui-color-verdant: #3ac267;
$ui-color-amber: #ffd900;
$ui-color-premium: #012250;
$ui-color-rosy: #d69ab7;
$ui-color-aquamarine: #06ca99;