.bg {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #0d3c3b;

    .base {
        font-family: 'khula_regular';
        // font-family: 'khula_regularpoppins_Medium';
        width: 100vw;
        height: 85vh;
        display: flex;
        // justify-content: center;
        flex-direction: column;
        position: absolute;

        max-width: 500px;


        background: #0d3c3b;
        color: white;

        .section_logo {
            padding-top: 1rem;


            .logo {
                width: 100%;
                display: flex;
                justify-content: center;

                .img {
                    width: 60%;
                }
            }

            .text {}
        }

        .section_info {
            display: flex;
            flex-direction: column;
            align-items: center;

            .alert {
                border-radius: 0.5rem;
                border: 1px solid #f7d77a;
                font-size: 1.3rem;
                padding: 20px;
                margin: 1rem;
                margin-top: 2rem;
                text-align: center;

                margin-bottom: 0px;
            }

            .subtitle {
                color: aliceblue;
                font-size: 1.3rem;
                // border: 2px solid #ffffff;
                padding-top: 2rem;
                border-radius: 0rem 0px 0.5rem 0.5rem;
                border-top: none;
            }

            .btn {
                font-size: 1rem;
                margin-top: 1.5rem;
                color: aliceblue;
                background-color: #687785;
                padding: 15px 20px;
                border-radius: 0.3rem;
                transition: .1s;
                width: 70%;

                &.c {
                    background-color: #3b7f65;
                }

                &:hover {
                    background-color: #63809c;
                    cursor: pointer;
                }

                &.multy {
                    text-align: center;
                    padding: 0px;
                    height: 3rem;
                    display: flex;
                    align-items: center;



                    &:hover {
                        .title {
                            display: none;
                        }

                        .buttons {
                            display: flex;
                            opacity: 1;

                        }
                    }

                    & .title {
                        font-size: 1rem;
                        color: rgb(255, 255, 255);
                        transition: .1s;
                        width: 100%;
                    }

                    &>.buttons {
                        
                        width: 100%;
                        height: 100%;
                        justify-content: space-around;
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        grid-gap: 1%;
                        display: none;
                        
                        

                        &>.btns {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            align-content: center;
                            line-height: unset;
                            overflow: hidden;
                            // padding: 10px 15px;
                            cursor: pointer;
                            transition: .2s;
                            width: 100%;
                            height: 100%;

                            &:hover {
                                background-color: #43a4ff;
                            }
                            // если перывй элемент
                            &:first-child {
                                border-radius: 0.5rem 0px 0px 0.5rem;
                            }
                            // если последний элемент
                            &:last-child {
                                border-radius: 0px 0.5rem 0.5rem 0px;
                            }
                        }
                    }
                }
            }
        }

        .footer {
            font-family: 'poppins_Medium';
            width: 100%;
            bottom: 0px;
            position: absolute;
            text-align: center;
            padding: 15px;
        }
    }
}