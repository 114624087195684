header {
  background-color: #fff;
}

header .nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 27px;
}

header .nav__logo {
  height: 30px;
}

header .nav__admin {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  cursor: pointer;
}

header .nav__admin-chel {
  color: #fff;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

header .nav__admin-chel-svg {
  transition: all ease 0.4s;
}

header .nav__admin-chel-str {
  width: 16px;
  height: 16px;
}

header .navbar {
  padding: 16px 27px;
  position: relative;
}

header .navbar__ul {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}

header .navbar__link {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 30px;
  color: #000;
  letter-spacing: 0;
  text-transform: none;
  text-decoration: none;
  font-variant-numeric: tabular-nums;
  transition: all ease 0.3s;
  padding-bottom: 5px;
  border-bottom: 2px solid rgba(0, 0, 0, 0);
}

header .navbar__link:hover {
  color: blue;
  border-bottom: 2px solid blue;
}

header li.disabled{
  pointer-events: none;
  opacity: 0.5;
}

header .navbar__hr {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  z-index: 0;
  background-color: rgba(97, 131, 162, .16);
}