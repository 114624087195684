// Header
@use '../../scss/_vars.scss'as *;

.disabled {
    pointer-events: none;
    opacity: 0.5;
}

main {
    margin-top: 0px !important;
}

// Container
.container {
    max-width: ($containerWidth + $containerPadding) + px;
    width: 100%;
    margin: 0 auto;
    padding: 0 $containerSidePadding + px;

    @media (max-width: $breakpoint1450) {
        max-width: 1200px;
    }

    @media (max-width: $breakpoint992) {
        max-width: 970px;
    }

    @media (max-width: $breakpoint768) {
        max-width: 740px;
    }

    @media (max-width: $breakpoint480) {
        max-width: none;
    }
}

// Body Locked
body {
    font-family: 'Open Sans', sans-serif;
    background-color: #f5f7fa;

    background: linear-gradient(27deg, rgb(0 0 0 / 67%) 0%, rgba(82, 181, 198, 0.74) 100%), #1e1e1e;

    &.locked {
        overflow: hidden;
    }
}

// Animations
// Fade
@keyframes fade {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.nav,
.navbar {
    svg {
        & path {
            fill: rgb(0 17 34 / 45%);
        }
    }
}



.tippy-arrow {
    display: none;
}

.tippy-box {
    background-color: rgba(51, 51, 51, 0);
}





////////////////////////////////////////

.tippy-box[data-theme~='nav1'] {

    .navbar {


        &__pos {
            padding-bottom: 1vh;
            margin-top: 5px;
            margin-left: 100px;
            width: 700px;
        }


        &__drow {
            width: 100%;
            width: max-content;

            display: flex;
            align-items: flex-start;
            gap: 16px;
            padding: 16px;
            box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.299);
            background-color: rgb(255, 255, 255);
            border-radius: 10px;
            white-space: nowrap;

            & .title {
                font-family: Inter, Arial, Helvetica, sans-serif;
                font-weight: 700;
                font-size: 15px;
                width: 100%;
                // padding: 16px 10px;
                padding: 12px 16px;
            }

            & .flex_box {
                display: flex;
                flex-wrap: nowrap;
                align-content: stretch;
                align-items: flex-start;
            }

            &-ul {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                // gap: 15px;
                gap: 2px;
                width: 100%;


                &-li {
                    font-family: 'Open Sans', sans-serif;
                    font-weight: 600;
                    font-size: 15px;
                    line-height: 24px;
                    color: #000;
                }

                & h2 {
                    padding-left: 10px;
                    padding-bottom: 10px;
                }


                & li {
                    width: 100%;
                    position: relative;
                    padding: 0px 10px;
                    padding: 10px 16px;
                    transition: all ease .3s;

                    &:hover {
                        cursor: pointer;
                        background-color: rgb(238, 238, 238);
                        border-radius: 10px;

                        & .navbar__drow-ul-link {
                            color: #000;

                        }
                    }

                    &.active {
                        &::before {
                            position: absolute;
                            top: 8px;
                            bottom: 8px;
                            width: 4px;
                            background-color: $mainColor;
                            border-radius: 2px;
                            content: "";
                            left: 0;
                        }

                        // дать родителю c классом .navbar__drow-ul-li

                    }
                }

                &-link {
                    font-family: 'Open Sans', sans-serif;
                    font-weight: 400;
                    font-size: 15px;
                    width: 100%;
                    line-height: 24px;
                    color: #001121f2;
                    transition: all ease .3s;

                    & .active {
                        border-left: 2px solid $mainColor;
                    }

                }

                &_a {
                    height: inherit;
                    width: inherit;
                }

            }

            &-ul2 {
                margin-top: 30px;
            }


        }

        &__drow2 {
            width: 100%;

            display: flex;
            align-items: flex-start;
            gap: 30px;
            box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.299);
            background-color: rgb(255, 255, 255);
            border-radius: 10px;
            padding: 20px;
            white-space: nowrap;


            & .title {
                font-weight: 700;
                font-size: 15px;
            }

            &-ul {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                // gap: 15px;
                gap: 2px;


                &-li {
                    font-family: 'Open Sans', sans-serif;
                    font-weight: 600;
                    font-size: 15px;
                    line-height: 24px;
                    color: #000;
                }

                & h2 {
                    padding-left: 10px;
                    padding-bottom: 10px;
                }

                & li {
                    padding: 0px 10px;

                    transition: all ease .3s;

                    &:hover {

                        background-color: rgb(238, 238, 238);
                        border-radius: 10px;

                        & .navbar__drow-ul-link {
                            color: #000;

                        }
                    }
                }

                &-link {
                    font-family: 'Open Sans', sans-serif;
                    font-weight: 400;
                    font-size: 15px;
                    width: 100%;
                    line-height: 24px;
                    color: #001121f2;
                    transition: all ease .3s;

                    & .active {
                        border-left: 2px solid $mainColor;
                    }

                }

            }


        }

        &__drow3 {
            max-width: 200px;


            display: flex;
            align-items: flex-start;
            gap: 30px;
            box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.299);
            background-color: rgb(255, 255, 255);
            border-radius: 10px;
            padding: 20px;
            white-space: nowrap;


            &-link {
                font-family: 'Open Sans', sans-serif;
                font-weight: 400;
                font-size: 15px;
                width: 100%;
                line-height: 24px;
                color: #001121f2;
                transition: all ease .3s;

                & .active {
                    border-left: 2px solid $mainColor;
                }

            }

        }


    }

    & .okno_add {

        animation: fade 0.1s linear;
        transition: all ease .3s;

        border-radius: 10px;
        white-space: nowrap;
        border: none;

        font-weight: 600;
        letter-spacing: 10px;
        font-size: 15px;
        line-height: 24px;
        letter-spacing: 0;
        color: $mainColor;
        background-color: #fff;
        box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.299);

        padding: 10px 40px;
        cursor: pointer;
        transition: all ease .4s;

        &:hover {
            background-color: rgb(238, 238, 238);
        }

        & .okno__btn {
            color: $mainColor;
        }


    }

    & .akk__drop {
        animation: fade 0.1s linear;
        transition: all ease .3s;
        margin-top: 10px;

    }

    & .akaunt {
        border-radius: 10px;
        background-color: #fff;

        z-index: 999;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.299);

        width: 240px;

        &__title {
            font-weight: 600;
            font-size: 15px;
            line-height: 24px;
            letter-spacing: 0;
            font-variant-numeric: normal;
            color: #000;
            padding: 10px 12px 0px 12px;
            cursor: pointer;
        }

        &__subtitle {
            font-weight: 400;
            font-size: 15px;
            line-height: 24px;
            letter-spacing: 0;
            font-variant-numeric: normal;
            color: #48556e !important;
            padding: 0px 12px;
            cursor: default;

        }

        &__link {
            font-weight: 600;
            font-size: 15px;
            line-height: 24px;
            letter-spacing: 0;
            font-variant-numeric: normal;
            color: rgb(42, 42, 168) !important;
            padding: 0px 0px 12px 12px;

        }

        &__ul {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;

            & li {
                display: flex;
                align-items: center;
                gap: 5px;
                padding: 10px 0px;
                width: 100%;
                padding: 10px 12px;

                &:hover {
                    background-color: rgb(238, 238, 238);
                    transition: all ease .3s;
                }
            }

            &-link {
                font-weight: 400;
                font-size: 15px;
                line-height: 20px;
                color: #48556e !important;
            }
        }
    }

    & .akk2 {
        animation: fade 0.1s linear;
        transition: all ease .3s;
        margin-top: 10px;


    }

    & .akaunt1 {
        z-index: 999;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.299);
        background-color: #fff;
        border-radius: 10px;
        padding: 5px 0px;



        &__ul {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;

            & li {
                display: flex;
                align-items: center;
                gap: 5px;
                padding: 10px 0px;
                width: 100%;
                padding: 10px 12px;

                &:hover {
                    background-color: rgb(212, 212, 212);
                    transition: all ease .3s;

                }
            }

            &-link {
                font-weight: 400;
                font-size: 15px;
                line-height: 20px;
                color: $ser;
            }
        }
    }

}





// right form 

.tippy-box[data-theme~='nav1'] {

    & .okno__btn {
        z-index: 9999999;
        // position: absolute;fs
        // top: 50px;


        &-ul {
            display: flex;
            // align-items: flex-end;
            flex-direction: column;
            justify-content: center;
            align-items: stretch;
            box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.299);
        }

        &-li {
            padding: 11px 15px;
            background-color: #fff;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            gap: 10px;
            // width: -webkit-fill-available;

            &:hover {
                background-color: rgb(238, 238, 238);
                transition: all ease .3s;
                cursor: pointer;

            }
        }


        &-link {
            font-size: 15px;
            line-height: 24px;
            letter-spacing: 0;
            color: #000;
            text-align: center;
            max-height: 48px;
            white-space: nowrap;


        }
    }

}

.popover-module_fixContentSize {
    display: grid;

    .submenu_submenu {
        display: -webkit-box;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        flex-direction: row;
        // background-color: var(--action-25-opaque);
        background-color: #f4fafe;

        .submenu_groups {
            position: relative;
            display: -webkit-box;
            display: flex;
            padding: 16px;
            background-color: var(--main-0);
            border-radius: 16px;
            gap: 16px;

            .submenu_group {
                .submenu_groupTitle {
                    debug-font-name: body500true;
                    font-family: Inter, Arial, Helvetica, sans-serif;
                    font-weight: 600;
                    font-size: 15px;
                    line-height: 24px;
                    letter-spacing: 0;
                    font-variant-numeric: normal;
                    padding: 10px 16px;
                }

                .submenu_groupContent {
                    display: -webkit-box;
                    display: flex;
                    gap: 16px;

                    .menu-module_reset {
                        margin: 0;
                        padding: 0;

                        .submenu_item {
                            .submenu_link {
                                display: block;
                                color: var(--ui-color-blue);
                                text-decoration: none;

                                .data-cell-module_dataCell {
                                    display: grid;
                                    grid-auto-rows: auto 1fr;
                                    grid-column-gap: 8px;
                                    grid-template-columns: var(--columns);
                                    align-items: center;
                                    height: -webkit-fit-content;
                                    height: -moz-fit-content;
                                    height: fit-content;
                                }

                                .menu-item-module_menuItem {
                                    --level-indent: 24px;
                                    position: relative;
                                    box-sizing: border-box;
                                    min-height: 44px;
                                    padding-top: 10px;
                                    padding-right: 16px;
                                    padding-bottom: 10px;
                                    padding-left: calc(16px + var(--level-indent)*var(--level-scale));
                                    list-style: none;
                                }

                                .menu-item-module_menuItem:hover.menu-item-module_roundHover,
                                .menu-item-module_menuItem[data-test-state=hover].menu-item-module_roundHover .menu-item-module_activeLeft {
                                    border-radius: 8px;

                                }

                                .menu-item-module_menuItem:hover,
                                .menu-item-module_menuItem[data-test-state=hover] .menu-item-module_roundHover {
                                    background-color: var(--neutral-50);
                                    cursor: pointer;
                                }



                                .data-cell-module_content {
                                    grid-row: span 2;

                                    .data-content-module_dataContent {
                                        --color-label: var(--ods-data-content-color-label, var(--data-content-color-label));
                                        --color-caption: var(--ods-data-content-color-caption, var(--data-content-color-caption));
                                        display: grid;
                                        gap: var(--gap);
                                        padding-top: var(--vertical-padding);
                                        padding-bottom: var(--vertical-padding);
                                        word-break: break-word;

                                        .data-content-module_label {
                                            color: #001122f2;
                                        }

                                        .data-content-module_label-size-500 {}

                                        .typography-module_table-500 {
                                            font-weight: 400;
                                            font-size: 15px;
                                            font-family: Inter, Arial, Helvetica, sans-serif;
                                            line-height: 20px;
                                            letter-spacing: 0;
                                            text-transform: none;
                                            text-decoration: none;
                                            font-variant-numeric: tabular-nums;
                                        }
                                    }

                                    .data-content-module_size-500 {
                                        --vertical-padding: 2px;
                                        --gap: 2px;
                                    }

                                    .data-content-module_light {
                                        --data-content-color-label: #001122f2;
                                        --data-content-color-caption: #001122a8;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

header {

    background-color: #fff;


    .nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 24px;
        height: 56px;

        & .LogoText {
            width: max-content;
            color: $mainColor;
            transform: scaleX(1.2);
            font-family: GT_bold;
            width: min-content;
            font-size: 1.7rem;
            margin: 0 auto;
            display: contents;

            & span {
                color: black;
                font-family: 'GT_regular';
                font-size: 1.3rem;
                margin-left: 0.3rem;
            }
        }

        &__logo {
            width: 148px;
            height: 20px;
        }

        &__admin {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 20px;

            cursor: pointer;

            &-chel {
                color: rgb(0 17 34 / 45%);
                font-size: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 10px;

                >.btn_tapi:hover {
                    .nav__admin-chel-svg {
                        transform: rotate(180deg);
                    }
                }


                &-svg {
                    transition: all ease .4s;
                }

                &-str {
                    width: 16px;
                    height: 16px;
                }


            }





        }


    }

    .navbar {
        padding: 0px 27px;
        position: relative;

        &__ul {
            display: flex;
            align-items: center !important;
            // gap: 20px;
            flex-wrap: wrap;
            // column-gap: 20px;

            >li {
                padding: 4px 0px;
                position: relative;
                cursor: pointer;
                padding: 9px 12px 11px 12px;

                // у первого ли
                &:first-child {
                    padding-left: 0;
                }

                >&.active {

                    // after
                    >&::after {
                        content: "";
                        position: absolute;
                        bottom: -0px;
                        left: 0;
                        width: 100%;
                        height: 2px;
                        background-color: $mainColor;
                        // background-color: var(blue);
                        border-radius: 2px;
                    }
                }

                >&:hover {
                    >a {
                        color: $mainColor;
                    }

                }
            }
        }

        // div



        &__link {
            font-family: 'Open Sans', sans-serif;
            font-weight: 400;
            font-size: 15px;
            line-height: 30px;
            letter-spacing: 0;
            text-transform: none;
            text-decoration: none;
            font-variant-numeric: tabular-nums;
            transition: all ease .3s;
            padding-bottom: 0px !important;
            color: #001122a8;
        }




        &__hr {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 2px;
            z-index: 0;
            background-color: rgba(97, 131, 162, .16);

        }



    }

}