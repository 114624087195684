// анимация пульсации радара
@keyframes pulse {
    0% {
        transform: scale(0.85);
        box-shadow: 0 0 0 0 rgba(29, 195, 20, 0.5);
    }

    50% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(222, 103, 103, 0);
    }

    100% {
        transform: scale(0.85);
        box-shadow: 0 0 0 10px rgba(222, 103, 103, 0);
    }

    // 100% {
    //     transform: scale(0.95);
    //     // box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    //     box-shadow: 0 0 0 0 rgba(29, 195, 20, 0.5);
    // }
}