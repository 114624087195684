// import _vars.scss
@import 'vars';
// $mainColor: rgb(0 91 255 / 100%);
// $ser: #48556e;

// fonts
@font-face {
    font-family: 'GT_bold';
    src: url('../fonts/gteestiprodisplay_bold.otf');
}

@font-face {
    font-family: 'poppins_Medium';
    src: url('../fonts/Poppins-Medium.ttf');
}

@font-face {
    font-family: 'khula_regular';
    src: url('../fonts/Khula-Regular.ttf');
}

@font-face {
    font-family: 'GT_regular';
    // src: url('../fonts/gteestiprodisplay_light.otf');
    // src: url('../fonts/gteestiprodisplay_medium.otf');
    src: url('../fonts/gteestiprodisplay_regular.otf');
    // src: url('../fonts/gteestiprodisplay_thin.otf');
}


html,
body #root {
    overflow-x: hidden !important;
    // overscroll-behavior: none !important;
}

:focus {
    outline: none;
}

.preloader {
    width: -webkit-fill-available;
    background-color: white;
    height: -webkit-fill-available;
    z-index: 9999999;
    position: absolute;
    position: fixed;
    top: 0;
    left: 0;
    margin: 0 auto;
    text-align: center;

    display: flex;
    justify-content: center;
    align-items: center;
}



.section__main {

    background-color: #f5f7fa;
    padding: 24px;

    .main {

        &__title {
            font-family: 'poppins_Medium', sans-serif;
            font-weight: 500;
            font-size: 36px;
            letter-spacing: normal;
            line-height: 48px;
            font-style: normal;
            text-transform: none;
            // margin-bottom: 24px;
            margin-bottom: 48px;
        }

    }

}



.tabs {
    width: 100%;

    &__header {
        display: flex;
        margin-top: 50px;
        position: relative;

        &__flex {
            display: flex;
            flex-wrap: nowrap;
            flex-direction: row;
            overflow-y: auto;
        }

        &-hr {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: rgb(97 131 162 / 16%);
        }

        &-item {
            font-family: 'Open Sans', sans-serif;
            padding: 10px;
            font-size: 15px;
            line-height: 20px;
            letter-spacing: 0;
            text-transform: none;
            text-decoration: none;
            transition: all ease .3s;
            cursor: pointer;
            border-bottom: 2px solid #f5f7fa00;
            display: flex;
            align-items: center;
            gap: 10px;
            min-width: fit-content;

            &:hover {
                color: $mainColor;
            }

            &.active {
                border-bottom: 2px solid $mainColor;
            }

            & span {
                width: 22px;
                height: 22px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $mainColor;
                border-radius: 100%;
                color: #fff;
            }

        }
    }

    &__content {
        padding: 24px;
        background-color: #fff;
        // margin-top: 20px;

        .navig {

            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            gap: 12px;

            // если ширина меньше 1000px
            @media (max-width: 1040px) {

                flex-direction: column;
                align-items: center;


                .navig__item {
                    width: 100%;
                }

                .navig__item2 {
                    width: 100%;
                    justify-content: flex-end;
                }
            }

            @media (max-width: 740px) {
                &__item {
                    gap: 10px !important;
                    flex-wrap: wrap !important;

                    &-form {
                        width: 100% !important;

                        &-input {
                            width: 100% !important;
                        }
                    }
                }
            }

            &__item {

                display: flex;
                align-items: center;
                gap: 30px;

                &-form {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0px;
                    position: relative;
                    width: fit-content;

                    .index_label,
                    .form__hr,
                    .find_icon path {
                        transition: transform .3s, font-size .3s, color .3s, bottom .3s, left .3s, top .3s, fill .3s, background-color .3s;
                    }

                    &.filled {
                        .index_label {
                            left: 3px;
                            bottom: 100%;
                            font-size: 12px;
                            // color: $mainColor;
                        }
                    }

                    &.active {
                        .find_icon path {
                            fill: $mainColor;
                        }

                        .form__hr {
                            background-color: $mainColor;
                        }

                        .index_label {
                            left: 3px;
                            bottom: 100%;
                            font-size: 12px;
                            color: $mainColor;
                        }
                    }

                    .find_icon {
                        margin: 4px;
                        width: 17px;

                        // path {
                        //     fill: $ui-color-gray;
                        // }
                    }


                    >label {
                        right: 0;
                        left: 25px;
                        bottom: 30%;
                        position: absolute;
                        color: $ui-color-gray-dark;
                        pointer-events: none;
                    }

                    &:focus {
                        & .icon4 path {
                            fill: $mainColor;
                        }

                    }

                    & .form__hr {
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        width: 100%;
                        height: 2px;
                        background-color: #ced5df;
                    }

                    &-svg {
                        width: 20px;
                        height: 20px;

                    }



                    &-input {
                        font-weight: 400;
                        font-size: 16px;
                        letter-spacing: normal;
                        line-height: 24px;
                        font-style: normal;
                        text-transform: none;
                        display: block;
                        width: 100%;
                        padding: 4px 0 7px;
                        // color: $ser;
                        color: $ui-color-gray-dark;

                        border: none;
                        background: transparent;
                        outline: none;
                        width: 300px;



                        &:focus {

                            & .icon4 path {
                                fill: $mainColor;
                            }

                        }



                    }


                }

                &-btn {
                    font-weight: 600;
                    font-size: 15px;
                    line-height: 24px;
                    color: $mainColor;
                    border: 1px solid $mainColor;
                    padding: 7px 15px;
                    border-radius: 3px;
                    transition: all ease .3s;
                    background-color: #fff;


                    &:hover {
                        background-color: $mainColor;
                        color: #fff;
                    }

                }


            }

            &__item2 {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 5px;

                &-btn {
                    position: relative;
                    display: flex;
                    align-items: center;
                    gap: 3px;
                    background-color: #fff;

                    font-weight: 600;
                    font-size: 15px;
                    line-height: 24px;
                    color: $mainColor;
                    border: 1px solid $mainColor;
                    padding: 7px 15px;
                    border-radius: 3px;
                    transition: all ease .3s;

                    &:hover {
                        background-color: $mainColor;
                        color: #fff;

                        & svg path {
                            fill: #fff;
                        }
                    }

                    & svg path {
                        fill: $mainColor;
                    }



                    & .okno__btn {
                        position: absolute;
                        top: 50px;
                        right: 0px;


                        &-ul {
                            display: flex;
                            align-items: center;
                            flex-direction: column;
                            justify-content: center;
                        }

                        &-li {
                            padding: 11px 15px;
                            background-color: #fff;
                            box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.299);

                        }

                        &-link {
                            font-size: 15px;
                            line-height: 24px;
                            letter-spacing: 0;
                            color: $ser;
                            text-align: center;
                            max-height: 48px;
                            white-space: nowrap;


                        }
                    }

                }

                & #navig__btn3 {

                    & .icon path {
                        fill: $mainColor;
                    }

                    &:hover {
                        & .icon path {
                            fill: #fff;
                        }
                    }



                }

                & #navig__btn {

                    & .icon2 path {
                        fill: $mainColor;
                    }

                    &:hover {
                        & .icon2 path {
                            fill: #fff;
                        }
                    }



                }

                & #navig__btn2 {

                    & .icon2 path {
                        fill: $mainColor;
                    }

                    &:hover {
                        & .icon2 path {
                            fill: #fff;
                        }
                    }



                }


            }

        }

    }

}

.main__header_s {
    display: flex;
    justify-content: space-between;
    padding: 2rem 1.5rem 1rem;
    background-color: rgb(255, 255, 255);
    width: 100%;


    .navig__item-form {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px;
        position: relative;
        width: fit-content;


        .index_label,
        .form__hr,
        .find_icon path {
            transition: transform .3s, font-size .3s, color .3s, bottom .3s, left .3s, top .3s, fill .3s, background-color .3s;
        }

        &.filled {
            .index_label {
                left: 3px;
                bottom: 100%;
                font-size: 12px;
                // color: $mainColor;
            }
        }

        &.active {
            .find_icon path {
                fill: $mainColor;
            }

            .form__hr {
                background-color: $mainColor;
            }

            .index_label {
                left: 3px;
                bottom: 100%;
                font-size: 12px;
                color: $mainColor;
            }
        }

        .find_icon {
            margin: 4px;
            width: 17px;
        }


        >label {
            right: 0;
            left: 25px;
            bottom: 30%;
            position: absolute;
            color: #48556e;
            pointer-events: none;
        }

        &:focus {
            & .icon4 path {
                fill: $mainColor;
            }

        }

        & .form__hr {
            position: absolute;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 2px;
            background-color: #ced5df;
        }

        &-svg {
            width: 20px;
            height: 20px;

        }

        &-input {
            font-weight: 400;
            font-size: 16px;
            letter-spacing: normal;
            line-height: 24px;
            font-style: normal;
            text-transform: none;
            display: block;
            width: 100%;
            padding: 4px 0 7px;
            color: $ser;
            border: none;
            background: transparent;
            outline: none;
            width: 300px;



            &:focus {

                & .icon4 path {
                    fill: $mainColor;
                }

            }



        }

        @media (max-width: 768px) {
            width: inherit;

            &-input {
                width: 100%;
            }
        }
    }

    @media (max-width: 768px) {
        justify-content: space-between;
        flex-direction: column;
        gap: 20px;
    }

}


.css-qprkun-MuiLinearProgress-root {
    background-color: #b999dc !important;
}

.css-1r8wrcl-MuiLinearProgress-bar2,
.css-5ir5xx-MuiLinearProgress-bar1 {
    background-color: $mainColor !important;
}