@import '../../../scss/animation.scss';

.projects {
    & .ant-progress-status-success .ant-progress-bg {
        background-color: #33a64a;
    }

    &__catalog {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 10px;
        margin: 0 auto;
        max-width: 100%;
        padding: 0 20px;

        @media (max-width: 968px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media (max-width: 680px) {
            grid-template-columns: repeat(1, 1fr);
        }
    }
}

.project {

    &[type='venture'] {

        & {
            background-color: hsla(0, 0%, 21%, 0.7);
            backdrop-filter: blur(20px) hue-rotate(0deg);
        }



        & .box .header .type {
            background-color: #33a64a;
            color: #ffffff;
        }
    }

    &[type='startup'] {
        & {
            backdrop-filter: blur(20px) hue-rotate(210deg);
        }

        & .box .header .type {
            background-color: #f5a623;
            color: #ffffff;
        }
    }



    position: relative;
    line-height: unset;
    font-family: 'poppins_Medium',
    Courier,
    monospace;


    border-color: hsla(0, 0%, 100%, .2);
    border-width: 1px;

    // min-height: 300px;
    // background-color: rgb(198, 192, 186);
    border-radius: .7rem;
    padding: 1rem 1.2rem;

    border: 1.2px solid hsla(0, 0%, 100%, .2);
    color: white;

    & .box {
        min-height: 360px;

        & .header {
            display: grid;
            grid-template-columns: 2fr 1fr;
            grid-gap: 10px;
            font-size: .6rem;

            display: flex;
            align-items: start;
            justify-content: center;
            align-content: center;

            margin-bottom: .8rem;


            & .logo {
                display: flex;
                align-items: center;

                & img {
                    width: 85%;
                }
            }

            & .type {
                font-size: .7rem;
                padding: 10px 12px;
                border-radius: .6rem;
                line-height: unset;

                position: absolute;
                right: 10px;
                top: 10px;

                // аниация радара
            }

            & .ra {
                
                position: absolute;
                left: 10px;
                top: 10px;
                background: rgba(47, 254, 140, 0.807);

                border-radius: 100%;


                width: 10px;
                height: 10px;
                animation: pulse 2s infinite;

                &.disabled{
                    animation: none;
                    background-color: #c1c1c1;
                }

            }
        }

        & .desc {
            font-size: .95em;
            font-family: monospace;
            color: #d8d8d8;
        }

    }

    &>.bottom_panel {
        position: absolute;
        bottom: 10px;
        width: 92%;



        & .numbers {
            margin-top: 3rem;
            display: flex;
            display: flex;
            flex-direction: column;

            .price {
                font-size: 1.7rem;
                margin-bottom: .8rem;
            }

            .progress_top {
                display: flex;
                justify-content: space-between;
                font-size: .7rem;
                margin-bottom: -4px;


                .desc {}

                .info {
                    color: #9a9a9a;
                }
            }

            &>.info {
                margin-top: 1rem;
                display: flex;
                justify-content: space-between;
                font-size: 1rem;

                display: grid;
                grid-template-columns: 1fr 1fr;
                align-items: flex-end;

                .title {
                    font-size: .8rem;
                    color: #d8d8d8;
                    margin-bottom: 5px;
                }

                .value {
                    font-size: 1.3rem;
                }
            }
        }

        & .buttons {
            margin-top: 3.5rem;
            margin-bottom: .5rem;
            justify-content: space-around;
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 7%;

            & .btn {
                display: flex;
                align-items: center;
                justify-content: center;
                align-content: center;
                line-height: unset;
                border-radius: .5rem;
                padding: 10px 15px;
                cursor: pointer;
                transition: .2s;


                &:hover {
                    opacity: .8;
                    transform: scale(1);
                }

                &.disabled {
                    opacity: .2;
                    cursor: not-allowed;
                    pointer-events: none;
                }

                &.buy {
                    background-color: #309ea5;
                }

                &.info {
                    border: 1px solid #c1c1c1;

                }
            }
        }
    }
}

// .progress{
//     display: grid;
//     grid-template-columns: 1fr .2fr;
//     align-items: center;
//     margin-bottom: 10px;
// }