.AlertContext {
    &.black-theme {
        $alert-blackTheme-bk: #231f1f;
        $alert-blackTheme-message: #fff;
        $alert-blackTheme-desc: #cbcbcb;
        $alert-blackTheme-close-color: #c9c9c9;

        background-color: $alert-blackTheme-bk !important;

        // icon
        .ant-notification-notice-close {
            color: $alert-blackTheme-close-color !important;
        }

        .ant-notification-notice-icon {
            color: $alert-blackTheme-close-color !important;
        }

        // ----

        // context
        .ant-notification-notice-message {
            color: $alert-blackTheme-message !important;
        }

        .ant-notification-notice-description {
            color: $alert-blackTheme-desc !important;
        }

        // ----

        //buttom
        .ant-notification-notice-btn {
            width: 100%;

            .ant-notification-notice-bottom {
                display: flex;
                justify-content: space-between;
                flex-direction: row;
                align-items: center;

                .ant-notification-notice-bottom-code {
                    color: $alert-blackTheme-desc !important;
                    display: flex;
                    align-items: center;
                    transition: 0.4s;
                    gap: 0.2rem;
                    cursor: copy;

                    .MuiSvgIcon-root {
                        transition: 0.1s;
                        transform: scale(0.8);
                    }

                    &:hover {
                        .MuiSvgIcon-root {
                            transform: scale(1);
                        }

                        color: $alert-blackTheme-message !important;
                    }

                    &:active {
                        cursor: pointer;
                        transition: 0.1s;
                        transform: scale(1.3);
                        // .MuiSvgIcon-root {
                            // transition: 0.01s;
                            // transform: scale(1.5);
                        // }
                    }
                }
            }
        }
    }
}