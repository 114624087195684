.okno {
    z-index: 999;
    transition: 2s;
    position: fixed;
    left: -500px;
    bottom: -500px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #172133;
    padding: 10px;
    // width: 420px;
    max-width: 420px;
    height: auto;
    border-radius: 10px;
    z-index: 999999;

    &.active {
        left: 10px;
        bottom: 10px;
    }

    &__item {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: flex-start;

        &-title {
            font-family: 'Inter', sans-serif;
            font-weight: 400;
            font-size: 14px;
            letter-spacing: normal;
            line-height: 20px;
            font-style: normal;
            text-transform: none;
            position: relative;
            left: 0;
            padding: 8px 8px;
            color: #fff;
            // word-break: break-all;
            word-break: break-word;
        }

        &-copy {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-left: 10px;


            &-text {
                transition: 0.1s color;
                // transition: ;
                color: #8d95a5;
                font-family: 'Inter', sans-serif;
                font-weight: 400;
                font-size: 14px;
                letter-spacing: normal;
                line-height: 20px;
            }

            & svg.icon,
            svg path {
                transition: 0.1s fill;
                fill: #8d95a5;
            }

            &:hover {

                & svg.icon,
                svg path {
                    fill: rgb(196, 192, 192);
                }

                .okno__item-copy-text {
                    color: rgb(196, 192, 192);
                }
            }
        }

    }

    &__item2 {
        color: #e59900;
        font-family: 'Inter', sans-serif;
        font-weight: 600;
        font-size: 15px;
        line-height: 24px;
        transition: all ease .3s;
        cursor: pointer;

        &:hover {
            color: #ffaa00;
        }
    }
}